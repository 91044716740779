<template>
  <div class="page-send-templates">
    <h2 class="p-title">推送模板</h2>
    <yos-tab-nav :list="tabNav" />
    <div class="p-card">
      <div class="tabs fix">
        <span :class="`item ${platform === 1 ? 'active' : ''}`" @click="onTabChange(1)">纵购云集</span>
        <span :class="`item ${platform === 2 ? 'active' : ''}`" @click="onTabChange(2)">纵购商城平台</span>
      </div>
      <yos-button class="yos-button-primary" to="/fun/send_templates/new">创建推送模板</yos-button>
      <form class="p-search-bar" @submit.prevent="onSubmit">
        <span>模板：</span>
        <yos-input
          v-model.trim="keyword"
          placeholder="搜索模板编号/标题"
        />
        &emsp;
        <span>公众号：</span>
        <yos-input
          v-model.trim="authorizer"
          placeholder="搜索公众号名称"
        />
        <yos-button class="yos-button-primary" type="submit">搜索</yos-button>
        <yos-button @click="onReset">重置</yos-button>
      </form>
      <table
          cellspacing="0"
          class="m-table"
        >
          <thead>
            <tr>
              <th>模板库编号</th>
              <th>模板标题</th>
              <th>关联公众号</th>
              <th>成功推送次数</th>
              <th>状态</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dataList" :key="item.id">
              <td>{{ item.libraryId }}</td>
              <td>{{ item.libraryTitle }}</td>
              <td>{{ item.authorizerAppids.split(',').length }}</td>
              <td>{{ item.successTimes }}</td>
              <td>{{ item.status === 1 ? '正常' : '禁用' }}</td>
              <td>
                <router-link class="option" :to="`/fun/send_templates/${item.id}`">编辑</router-link>
              </td>
            </tr>
          </tbody>
      </table>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import YosTabNav from '../../components/other/tab-nav'
import YosPager from '../../components/other/yos-pager'
import { reactive, toRefs } from 'vue'
import { useServer, usePage, useTabNav } from '../../hook'
import { useRoute, useRouter } from 'vue-router'
import {
  getSendTemplates
} from '../../data'
import { serialize, getPageNavs } from '../../util'

export default {
  components: {
    YosTabNav,
    YosPager
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const nav = getPageNavs('fun')
    const state = reactive({
      keyword: route.query.keyword || '',
      authorizer: route.query.authorizer || '',
      platform: parseInt(route.query.platform) || 1,
      totalPage: -1,
      dataList: []
    })

    const { page, baseUrl } = usePage('/fun/send_templates', { keyword: state.keyword })

    const setSendTemplatesParams = useServer(getSendTemplates, ({ sendTemplates, totalPage }) => {
      state.dataList = sendTemplates
      state.totalPage = totalPage
    })

    const onTabChange = (idx) => {
      const params = { keyword: '', authorizer: '', platform: idx, page: 1 }
      state.platform = idx
      state.keyword = ''
      state.authorizer = ''
      router.replace(serialize(baseUrl, params))
      setSendTemplatesParams(params)
    }

    const onPageChange = (page) => {
      setSendTemplatesParams({ keyword: state.keyword, authorizer: state.authorizer, platform: state.platform, page })
    }

    const onSubmit = () => {
      const params = { keyword: state.keyword, authorizer: state.authorizer, platform: state.platform, page: 1 }
      router.replace(serialize(baseUrl, params))
      setSendTemplatesParams(params)
    }

    const onReset = () => {
      state.keyword = ''
      state.authorizer = ''
      onSubmit()
    }

    setSendTemplatesParams({ keyword: state.keyword, authorizer: state.authorizer, platform: state.platform, page })

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      page,
      baseUrl,
      onTabChange,
      onPageChange,
      onSubmit,
      onReset
    }
  }
}
</script>

<style lang="postcss">
.page-send-templates {
  .tabs {
    line-height: 40px;
    border-bottom: 2px solid #e0e1e2;
    font-size: 16px;
    margin-bottom: 20px;


    .item {
      color: #353535;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      padding: 0 10px;
      display: inline-block;
      float: left;
      margin-bottom: -2px;
      cursor: pointer;

      &.active {
        border-bottom: 3px solid #1aad19;
        color: #1aad19;
      }
    }
  }
}
</style>
